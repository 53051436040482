export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Home.navigationTitle',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: 'Settings',
        route: 'pages-account-setting',
        icon: 'FileIcon',
      },
      {
        title: 'BrandSetting.navigationTitle',
        route: 'pages-user-setting',
        icon: 'FileIcon',
      },
      {
        title: 'Connection',
        route: 'pages-connection',
        icon: 'ShareIcon',
      },
      {
        title: 'Invite',
        route: 'invites',
        icon: 'ShareIcon',
      },
      {
        title: 'Products',
        route: 'pages-product',
        icon: 'ShareIcon',
      },
      {
        title: 'Lots',
        route: 'pages-lot',
        icon: 'ShareIcon',
      },
    ],
  },
]
